import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"
import ContentHeader from "../components/contentheader"
import InPageNavigation from "../components/inpage-navigation"
import BreadCrumbs from "../components/breadcrumbs"

function elekTemplate({data}) {
  const { markdownRemark } = data
  const { tableOfContents, frontmatter} = markdownRemark
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <Header title="Elektrotechniek" site="elek"/>
      <div class="fluid-container">
        <BreadCrumbs site="elek" page={frontmatter.title} link={frontmatter.path}/>
        <div class="row">
          <InPageNavigation toc={tableOfContents}/>

          <article id="portal-column-content" class="cell col-sm-9 mbottom-default">
            <ContentHeader content={markdownRemark} />
          </article>

        </div>

      </div>
    </Layout>
  )
}

export default elekTemplate

export const query = graphql`
  query($fileAbsolutePath: String!) {
    markdownRemark(fileAbsolutePath: {eq: $fileAbsolutePath} ) {
      frontmatter {
        title
        path
      }
      html
      tableOfContents(absolute: false)
    }
  }
`
import React from "react"


const ContentHeader = ({content}) => (
    <><h1>{content.frontmatter.title}</h1>
    <div dangerouslySetInnerHTML={{ __html: content.html }}> 
    
    </div>
    </>
)

export default ContentHeader

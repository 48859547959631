import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import { FaArrowDown } from "react-icons/fa"

export const useScrollHandler = () => {
    const [scroll, setScroll] = useState(0)

    useEffect(() => {
        const onScroll = () => {
            const scrollCheck = window.scrollY > 400 //TODO magic number
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck)
            }
        }
        document.addEventListener("scroll", onScroll)
        return () => {
            document.removeEventListener("scroll", onScroll)
        }
    }, [scroll, setScroll])
    return scroll
}


const InPageNavigation = ({ toc }) => {
    const scroll = useScrollHandler()
    const scrollClass = scroll ? "padding-default bg-tertiary stick" : "padding-default bg-tertiary"
    const arrowToTop = scroll ? "↑" : "" 
    return (
    <div class=" inpage-nav-container col-sm-3 xs-hide">
        <div class="content-wrapper bg-tertiary mbottom-small padding-20">
            <h3 class="h2">Snel naar</h3>
            <ul class="linklist linklist-dark">
                <li><a download href="/Elektrotechniek-bachelor.pdf">Download de bachelorbrochure<FaArrowDown/></a></li>
                <li><a download href="/Elektrotechniek-master.pdf">Download de masterbrochure<FaArrowDown/></a></li>
            </ul>
        </div>
        <div role="navigation" data-lockfixed="true" class={scrollClass}>
            <h2>
                Op deze pagina <span style={{float: "right"}}><a style={{border: 0}}href="#top" >{arrowToTop}</a></span>
            </h2>
            <div id="toc" class="tocify linklist linklist-dark" dangerouslySetInnerHTML={{ __html: toc }}>
            </div>
        </div>
    </div>
    )
}

export default InPageNavigation
import React from "react"
import { Link } from "gatsby"

const BreadCrumbs = ({site, page, link}) => {
    let li;
    if (site == "elek"){
        if (link == "/") {
        li = [<li class="active"><Link to="/elek">Elektrotechniek</Link></li>]
        } else{
            li = [<li><Link to="/elek">Elektrotechniek</Link></li>,
            <li class="active"><Link to={"/elek"+link}>{page}</Link></li>]
        }
    } else if (page) {
        li = <li class="active"><Link to={link}>{page}</Link></li>
    }

    return (
    <ol id="breadcrumbs" class="breadcrumb">
        <li>
            <a href="https://www.ugent.be/ea/nl">Home</a>
        </li>
        {/*<li class="">
            <a href="https://www.ugent.be/ea/nl/faculteit">Over de faculteit</a>
        </li>
        <li class="">
            <a href="https://www.ugent.be/ea/nl/faculteit/onderwijsondersteuning">Facultaire Dienst Onderwijsondersteuning</a>
        </li>*/}
        <li class="">
            <a href="https://www.ugent.be/ea/nl/faculteit/onderwijsondersteuning/oplir">Opleidingen ingenieurswetenschappen</a>
        </li>
        {li}
    </ol>)
}

export default BreadCrumbs
